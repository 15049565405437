import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { navigate, Link } from 'gatsby';

import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer';
import BasicResponsiveVideo from '@babelcoder/gatsby-theme-base/src/components/BasicResponsiveVideo';
import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme';
import useRegistration from '@babelcoder/gatsby-theme-courses/src/hooks/useRegistration';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';
import useSubscription from '@babelcoder/gatsby-theme-courses/src/hooks/useSubscription';

const Header = styled.header`
  ${({ theme, bg }) => css`
    color: ${theme.colors.neutral.gray1500};

    ${up('large')} {
      background-color: ${bg};
      padding: ${courseTheme.gutter};
      padding-bottom: calc(${courseTheme.gutter} * 2);
    }
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-areas:
      'video'
      'title'
      'excerpt'
      'register-button';
    position: relative;
    background-color: ${theme.colors.neutral.white};
    overflow: hidden;

    ${up('large')} {
      max-width: ${courseTheme.maxWidth.large};
      grid-template-areas:
        'video title'
        'video excerpt'
        'video register-button';
      grid-template-columns: 65% 1fr;
      grid-template-rows: min-content;
      align-items: center;
      column-gap: ${courseTheme.gutter};
      margin: 0 auto;
      border-radius: ${theme.round.xlarge};
    }
  `};
`;

const VideoWrapper = styled.div`
  grid-area: video;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    grid-area: title;
    font-size: ${theme.fontSizes.large};
    margin: ${courseTheme.gutter} 0;
    padding: 0 ${courseTheme.gutter};
    line-height: 1.5;

    ${up('large')} {
      font-size: ${theme.fontSizes.medium};
      padding-left: 0;
      margin-bottom: 0;
      line-height: 1.25;
    }

    ${up('xlarge')} {
      font-size: ${theme.fontSizes.large};
    }
  `}
`;

const Excerpt = styled.p`
  ${({ theme }) => css`
    grid-area: excerpt;
    margin: 0;
    padding: 0 ${courseTheme.gutter};
    font-size: ${theme.fontSizes.normal};
    color: ${theme.colors.neutral.gray900};

    ${up('large')} {
      padding-left: 0;
    }
  `}
`;

const statusButtonStyle = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    margin: ${courseTheme.gutter};
    color: ${theme.colors.neutral.white};
    text-align: center;
    border-radius: 2rem;
    border: none;
    outline: none;

    ${up('large')} {
      margin: 0 ${courseTheme.gutter} ${courseTheme.gutter} 0;
    }
  `}
`;

const StatusButton = styled.button.attrs({
  type: 'button',
})`
  ${statusButtonStyle};

  ${({ theme }) => css`
    grid-area: register-button;
    background-color: ${theme.colors.main.primary};
    cursor: pointer;
  `}
`;

const RegistrationPending = styled.div`
  ${statusButtonStyle};

  background-color: ${({ theme }) => theme.colors.main.info};
`;

const OverViewLink = styled(Link)`
  ${statusButtonStyle};

  background-color: ${({ theme }) => theme.colors.main.primary};
`;

function CourseHeader({
  slug,
  title,
  excerpt,
  promotedVideo,
  price,
  style: {
    header: { bgColor },
  },
}) {
  const { user, isAuthLoaded } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const { subscription, isLoading: isSubscriptionLoading } = useSubscription(
    slug
  );
  const {
    isLoading: isRegistrationLoading,
    exists: registrationExists,
  } = useRegistration(slug);
  const {
    getCoursePath,
    getCourseQuotationPath,
    getCourseOverviewPath,
  } = useCoursesPath();
  const register = useCallback(() => {
    if (!user) {
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'warning',
          title: 'ต้องการการยืนยันตัวตน',
          message: 'โปรดเข้าสู่ระบบก่อนดำเนินการลงทะเบียน',
        },
      });
      navigate('/auth/sign-in', { state: { prevPath: getCoursePath(slug) } });
      return;
    }

    if (typeof fbq === 'function') {
      // eslint-disable-next-line no-undef
      fbq('track', 'InitiateCheckout', {
        content_ids: [slug],
        content_name: title,
        value: price,
        currency: 'THB',
      });
    }
    navigate(getCourseQuotationPath(slug));
  }, [user, dispatch, slug, getCourseQuotationPath, getCoursePath]);

  const getStatus = useMemo(() => {
    if (!isAuthLoaded) return null;
    if (isSubscriptionLoading) return null;

    if (subscription) {
      return (
        <OverViewLink to={getCourseOverviewPath(slug)}>เริ่มเรียน</OverViewLink>
      );
    }
    if (isRegistrationLoading) return null;

    if (registrationExists) {
      return <RegistrationPending>กำลังออกใบเสนอราคา</RegistrationPending>;
    }

    return <StatusButton onClick={register}>ขอใบเสนอราคา</StatusButton>;
  }, [
    slug,
    isAuthLoaded,
    isRegistrationLoading,
    registrationExists,
    register,
    subscription,
    isSubscriptionLoading,
    getCourseOverviewPath,
  ]);

  return (
    <Header bg={bgColor}>
      <Container>
        <VideoWrapper>
          <BasicResponsiveVideo
            url={promotedVideo}
            title={title}
            desc={excerpt}
            controls={true}
          ></BasicResponsiveVideo>
        </VideoWrapper>
        <Title>{title}</Title>
        <Excerpt>{excerpt}</Excerpt>
        {getStatus}
      </Container>
    </Header>
  );
}

export default CourseHeader;
