import React from 'react'
import styled, { css } from 'styled-components'

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO'
import UnitsTableProvider from '@babelcoder/gatsby-theme-courses/src/components/mdx/UnitsTableProvider'
import useCourseSchema from '@babelcoder/gatsby-theme-courses/src/hooks/useCourseSchema'
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import CourseHeader from './CourseHeader'
import CourseDetails from './CourseDetails'

const Wrapper = styled.article`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray200};
  `}
`

function Course(props) {
  const { slug, title, excerpt, coverImage } = props
  const {
    src: coverImageURL,
    width: coverImageWidth,
    height: coverImageHeight,
  } = coverImage.childImageSharp.fixed
  const getSchema = useCourseSchema()
  const { getCoursePath } = useCoursesPath()

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={getCoursePath(slug)}
        coverImageWidth={coverImageWidth}
        coverImageHeight={coverImageHeight}
        coverImageURL={coverImageURL}
        desc={excerpt}
        schema={getSchema(props)}
        ignoreAuthorAndPublisher={true}
      ></SEO>
      <UnitsTableProvider>
        <Wrapper>
          <CourseHeader {...props}></CourseHeader>
          <CourseDetails {...props}></CourseDetails>
        </Wrapper>
      </UnitsTableProvider>
    </>
  )
}

export default Course
